import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import moment from "moment";
import { uniq, findIndex } from "@antv/util";
import { debounce } from "lodash";
import _ from "lodash";

export default function DashboardBySeat({
  DailyNetIncomeToSend,
  MyChooce2,
  MonthlyNetIncomeToSend,
}) {
  const currentDate = moment().format("MMMM DD, YYYY");
  //console.log(currentDate);
  //console.log(MyChooce2);
  //console.log(DailyNetIncomeToSend);

  // console.log(aa);
  //console.log(MonthlyNetIncomeToSend);

  const formatter = (value, name, props) => {
    return `$${value}`;
  };

  const COLOR_PLATE_10 = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
  ];
  const container = document.getElementById("container");
  // Custom tooltip formatter function
  const customTooltipFormatter = (datum) => {
    return {
      name: datum.name,
      value: `$ ${datum.netIncome
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    };
  };

  const config = {
    data: DailyNetIncomeToSend,
    xField: "hour",
    yField: "netIncome",
    legend: false, // This will hide the legend
    seriesField: "name",
    yAxis: {
      label: {
        formatter: (v) =>
          `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ name }) => {
        return name + "circle";
      },
      style: ({ hour }) => {
        return {
          r: hour + "hour",
        };
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };

  const config1 = {
    data: MonthlyNetIncomeToSend,
    xField: "date",
    yField: "netIncome",
    seriesField: "name",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ name }) => {
        return "circle";
      },
      style: ({ hour }) => {
        return {
          r: hour, // 4 个数据示一个点标记
        };
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ width: "100%" }), []);

  // const data = [
  //   { date: "2023-03-01", value: 10 },
  //   { date: "2023-03-02", value: 20 },
  //   { date: "2023-03-03", value: 15 },
  //   { date: "2023-03-04", value: 40 },
  //   // add more data here
  // ];

  // const monthStart = moment(data[0].date).startOf("month");
  // const monthEnd = monthStart.clone().endOf("month");
  // const datesInRange = [];
  // let currentDate1 = monthStart.clone();

  // while (currentDate1.isSameOrBefore(monthEnd)) {
  //   datesInRange.push(currentDate1.format("YYYY-MM-DD"));
  //   currentDate1.add(1, "day");
  // }

  // const chartData = datesInRange.map((date) => {
  //   const dataItem = _.find(data, { date });
  //   return { date, value: dataItem ? dataItem.value : null };
  // });

  return (
    <div
      style={{
        paddingRight: "1%",
        paddingLeft: "2.5%",
      }}
    >
      {MyChooce2 === "Daily" ? (
        <>
          <div style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}>
            <span style={{ color: "#1a1523", fontSize: 18 }}>
              Daily Net Income Overview{" "}
              {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
              <span style={{ color: "#6f6e77" }}>{currentDate}</span> */}
            </span>
          </div>
          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
              <div>
                <Line {...config} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}>
            <span style={{ color: "#1a1523", fontSize: 18 }}>
              Monthly Net Income Overview{" "}
              {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
              <span style={{ color: "#6f6e77" }}>{currentDate}</span> */}
            </span>
          </div>
          <Line {...config1} />
        </>
      )}
      {/* <Line data={chartData} xField="date" yField="value" /> */}
    </div>
  );
}
