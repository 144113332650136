import React, { useEffect, useState, useCallback, CSSProperties } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import ControlPanel from "./../Images/ControlPanel.png";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { color } from "@mui/system";
import { MyUrl, CallFromO2R, ForLogOut } from "../App";
import ProfilePicture1 from "./../Images/ProfilePicture1.png";
import Menu1 from "./../Images/Menu1.png";
import monetizematrixlogo from "./../Images/monetizematrixlogo.png";
import { parse } from "date-and-time";
import jwt_decode from "jwt-decode";
var jwtToken = "";

var aa = 0;

var OpenDrawer = true;

export default function NewHeader({ PageName, onUpdate }) {
  const source = axios.CancelToken.source();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("myPassword");
  const [email, setEmail] = useState("");
  const [bgColor, setBgColor] = useState("white");
  const [bgColor1, setBgColor1] = useState("white");
  const [data, setData] = useState("");
  const [ColorButton, setColorButton] = useState(1);
  let [thecolor, setthecolor] = useState();
  const [thebutton, setthebutton] = useState("");
  const [data3, setData3] = useState([]);

  const RefreshFunc = async () => {
    console.log("jwtToken");
    console.log(jwtToken);
    // function handleFocus() {
    //   console.log("Component is focused");
    // }
    // function handleBlur() {
    //   console.log("Component is unfocused");
    // }
    // window.addEventListener("focus", handleFocus);
    // window.addEventListener("blur", handleBlur);
    // if (items1 === null) {
    const items = sessionStorage.getItem("Token");
    console.log(items);
    var parsed = JSON.parse(items);
    const token = sessionStorage.getItem("Token");
    const decodedToken = jwt_decode(token);
    console.log(decodedToken);
    setName(
      decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
    );
    setEmail(
      decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
      ]
    );

    console.log(decodedToken.Id);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    console.log(role);

    // const token = sessionStorage.getItem("Token");
    // const decodedToken = jwt_decode(token);
    // const role = decodedToken.role;
    //   setCheckTypeOfUser(parsed.role);
    jwtToken = parsed;
    const base64Url = jwtToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        console.log(jwtToken);
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: jwtToken.replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            console.log("New Token");
            console.log("New Token");
            parsed = response.data.accessToken;
            console.log(response.data.accessToken);
            const updatedObjString = JSON.stringify(response.data.accessToken);
            console.log(updatedObjString);
            sessionStorage.setItem("Token", updatedObjString);
            // GetDailyIncomeFunc();
            // GetMonthlyIncomeFunc();
            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Token is still valid");
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = parsed;
      // GetDailyIncomeFunc();
      // GetMonthlyIncomeFunc();
      return () => {
        source.cancel("Component unmounted");
      };
    }

    // return () => {
    //   window.removeEventListener("focus", handleFocus);
    //   window.removeEventListener("blur", handleBlur);
    // };
  };
  useEffect(() => {
    RefreshFunc();
  }, []);
  // console.log(parsed);
  // console.log(myPassword);
  //console.log(MyToken);

  let history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const { pathname } = useLocation();
  const [height, width] = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, height]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }

  const LogOut = async () => {
    await RefreshFunc();

    console.log("its in");
    try {
      console.log("its in2");

      axios
        .delete(`${MyUrl}/api/RefreshToken/revoke`, {
          headers: {
            Authorization: `Bearer ${jwtToken.replace("Bearer ", "")}`,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
    //localStorage.removeItem("User");
    sessionStorage.removeItem("User");
    //localStorage.removeItem("Token");
    sessionStorage.removeItem("Token");
    console.log("User deleted");
    const items = sessionStorage.getItem("Token");
    console.log(items);
    var parsed = JSON.parse(items);
    const token = sessionStorage.getItem("Token");
    //history.push("/Login");
    window.location.href = `${ForLogOut}`;

    //window.open(`${ForLogOut}`, "_blank");
    // window.open(
    //   `${responseData.url}?Authorization=Basic ${token}`,
    //   "_blank"
    // );
  };
  const GoToUsersTbale = async () => {
    console.log("GoToUsersTbale");
    console.log(name);
    //console.log(myPassword);
    //console.log(data3);

    var userObj = {
      name: name,
      password: "myPassword",
      //data2: data3,
    };
    history.push("/UsersTable", { userObj2Send: userObj });
  };
  const GoToAdminDashboard = async () => {
    console.log(userObj2Send);
    var userObj2Send = {
      name: name,
      password: "myPassword",
      //  data3: data3,
      email1: email,
    };

    history.push("/AdminDashboard", { userObj2Send });
  };

  function getUrl() {
    const items = sessionStorage.getItem("Token");
    console.log(items);
    var parsed = JSON.parse(items);
    console.log(parsed);

    let base64 = require("base-64"); // install it before use from npm i base-64
    // console.log(parsed.name);
    // console.log(myPassword);
    try {
      axios
        .get(
          `${CallFromO2R}/api/Account/myUrls
            `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          console.log(data);
          setData(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  }
  useEffect(() => {
    getUrl();
    console.log("This is my function.");
  }, []);

  setTimeout(() => {
    OpenDrawer = true;
  }, 500);

  const handleCheckboxChange = (event) => {
    //const childValue = event.target.checked;
    onUpdate(event);
    setTimeout();
  };

  return (
    <div
      style={{
        paddingTop: "1%",
        //  background: "gray",
        height: 50,
        paddingLeft: "2.5%",
        paddingRight: "1%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* <Avatar src="./../../Images/monetizematrixlogo.png" sx={{ width: 32, height: 32 }} /> */}
      {width > 766 ? (
        <></>
      ) : (
        <>
          <div
            onClick={() => {
              OpenDrawer = !OpenDrawer;
              handleCheckboxChange(!OpenDrawer);
            }}
          >
            <img
              //src="./../Images/monetizematrixlogo.png"
              src={require(`./../Images/monetizematrixlogo.png`)}
              //height="20%"
              //width="20%"
              style={{ width: 40 }}
            ></img>
          </div>
        </>
      )}

      <div>
        <a style={{ color: "#1a1523", fontSize: 20 }}>{PageName}</a>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open1 ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
              >
                <div style={{ background: "white", borderRadius: 100 }}>
                  <img
                    style={{
                      width: "auto",
                      maxWidth: width < 1500 ? 50 : 50,
                      padding: "20%",
                    }}
                    src={Menu1}
                  ></img>
                </div>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                borderRadius: 5,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                minWidth: 300,
                justifyContent: "space-around",
                paddingTop: "6%",
                paddingBottom: "6%",
              }}
            >
              <div
                onMouseEnter={() => {
                  setBgColor("#e9e8ea");
                }}
                onMouseLeave={() => {
                  setBgColor("");
                }}
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  height: 100,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "45%",
                  borderRadius: 20,
                  background: bgColor,
                }}
                onClick={() => {
                  GoToUsersTbale();
                }}
              >
                <img src={ControlPanel} style={{ width: 50 }}></img>
                Admin Panel
              </div>
              <div
                onMouseEnter={() => {
                  setBgColor1("#e9e8ea");
                }}
                onMouseLeave={() => {
                  setBgColor1("");
                }}
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  height: 100,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "45%",
                  borderRadius: 20,
                  background: bgColor1,
                }}
                onClick={() => {
                  GoToAdminDashboard();
                }}
              >
                <img src={ProfilePicture1} style={{ width: 50 }}></img>
                Account
              </div>
            </div>

            <Divider
              style={{ opacity: 1, marginLeft: "8%", marginRight: "8%" }}
            />
            <div style={{ paddingTop: 10, paddingLeft: 10 }}>
              <h1 style={{ fontSize: 15, paddingTop: 10, opacity: 0.5 }}>
                Services
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: 300,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {Array.isArray(data.responseData) ? (
                data &&
                data.responseData.map((responseData) => (
                  <div
                    onMouseEnter={() => {
                      aa = responseData.id;
                      setthebutton(responseData.id);
                      setthecolor("green");
                      console.log(thebutton);
                    }}
                    onMouseLeave={() => {
                      aa = 0;
                      setthecolor("black");
                    }}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      borderRadius: 30,
                      background: responseData.id === aa ? "#e9e8ea" : "",
                      flex: width > 481 ? "0 1 45%" : "0 1 45%",
                      //  border: responseData.id === aa ? `1px solid black` : "",
                    }}
                  >
                    {responseData.imageUrl !== "" ? (
                      <div
                        onClick={(e) => {
                          console.log("its ok ");
                          const token = btoa(name + ":" + myPassword) || null; // encode the token with btoa or set to null
                          //  const redirectUrl = `http://${responseData.url}?Authorization=Basic ${token}`;
                          //      const redirectUrl = `${responseData.url}?Authorization=Basic ${token}`; //LinkToBI
                          // window.location = redirectUrl;
                          window.open(
                            `https://${responseData.url}?Authorization=Basic ${token}`,
                            "_blank"
                          );
                        }}
                        style={{
                          borderRadius: 20,
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          width: "90%",
                        }}
                      >
                        <img
                          src={require(`./../Images/${responseData.imageUrl}.png`)}
                          height="20%"
                          width="40%"
                          style={{ margin: 25 }}
                        ></img>
                        <h4
                          style={{
                            // color: "#9333ea",
                            fontSize: "100%",
                            textAlign: "center",
                          }}
                        >
                          {responseData.name}
                        </h4>
                      </div>
                    ) : (
                      <>
                        <Button variant="contained">{responseData.url}</Button>
                      </>
                    )}

                    <br></br>
                  </div>
                ))
              ) : (
                <>
                  <h5>You dont have any website!</h5>
                  <div
                    style={{ paddingTop: width > 1500 ? "15%" : "8%" }}
                  ></div>
                </>
              )}
            </div>
          </Menu>
        </React.Fragment> */}

        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick1}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <div style={{ background: "white", borderRadius: 100 }}>
                  <img
                    style={{ width: "auto", maxWidth: width < 1500 ? 50 : 50 }}
                    src={ProfilePicture1}
                  ></img>
                </div>
                {/* <Avatar
                src="./../../Images/monetizematrixlogo1.png"
                sx={{ width: 32, height: 32 }}
              /> */}
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl1}
            id="account-menu"
            open={open1}
            onClose={handleClose1}
            onClick={handleClose1}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },

                borderRadius: 5,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                display: "flex",
              }}
            >
              <img src={ProfilePicture1} style={{ width: 75 }}></img>
            </div>
            <Typography sx={{ minWidth: 300 }}>
              <div
                style={{
                  padding: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  display: "flex",
                  fontWeight: "bold",
                }}
              >
                {name}
              </div>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <div
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  display: "flex",
                  paddingBottom: "5%",
                }}
              >
                {email}
              </div>
            </Typography>

            <Divider
              style={{ opacity: 1, marginLeft: "8%", marginRight: "8%" }}
            />

            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                display: "flex",
                paddingTop: "5%",
                paddingBottom: "5%",
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  history.push(`/ChangePassword`, {
                    name1: name,
                    password1: "myPassword", //myPassword
                    email1: email,
                    // data2: data3,
                  });
                }}
              >
                Manage your account
              </Button>
            </div>
            <Divider
              style={{ opacity: 1, marginLeft: "8%", marginRight: "8%" }}
            />

            <div
              style={{
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                display: "flex",
              }}
            ></div>

            <div
              style={{
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                display: "flex",
              }}
            >
              <Button
                style={{
                  backgroundColor: "white",
                  textTransform: "none",
                  width: "80%",
                  borderRadius: 10,
                }}
                variant="contained"
                onClick={() => {
                  LogOut();
                }}
              >
                <a style={{ color: "black" }}>Logout</a>
              </Button>
            </div>
          </Menu>
        </React.Fragment>
      </div>
    </div>
  );
}
