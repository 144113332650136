import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { MyUrl } from "../../../App";
import axios from "axios";

var MyArray = [];
export default function CampaignManagementCampaignOptimizationOverview({
  SendDataToCampOptimization,
  startDate,
  endDate,
}) {
  const [Value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      flex: width > 766 ? 1 : null,
      field: "Id",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",
    },
    {
      minWidth: 150,
      flex: width > 766 ? 1 : null,
      field: "CampaignName",
      filter: "agNumberColumnFilter",
      headerClass: "my-header-class",

      valueFormatter: (params) => `${params.value}`,
    },
    // {
    //   minWidth: 150,
    //   flex: width > 766 ? 1 : null,
    //   field: "SEAT",
    //   filter: "agNumberColumnFilter",
    //   headerClass: "my-header-class",

    //   valueFormatter: (params) => `${params.value}`,
    // },
    {
      minWidth: 150,
      flex: width > 766 ? 1 : null,
      field: "ENV",
      filter: "agNumberColumnFilter",
      headerClass: "my-header-class",

      valueFormatter: (params) => `${params.value}`,
    },

    {
      minWidth: 150,
      flex: width > 766 ? 1 : null,
      field: "Req",
      filter: "agNumberColumnFilter",
      headerClass: "my-header-class",

      valueFormatter: (params) =>
        `${params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    },
    {
      minWidth: 150,
      flex: width > 766 ? 1 : null,
      field: "Imp",
      filter: "agNumberColumnFilter",
      headerClass: "my-header-class",

      valueFormatter: (params) =>
        `${params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    },

    {
      minWidth: 150,
      flex: width > 766 ? 1 : null,
      field: "Revenue",
      filter: "agNumberColumnFilter",
      headerClass: "my-header-class",

      valueFormatter: (params) =>
        `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    },
    {
      minWidth: 150,
      flex: width > 766 ? 1 : null,
      field: "QS",
      filter: "agNumberColumnFilter",
      headerClass: "my-header-class",
      cellStyle: (params) =>
        params.value >= 5
          ? { color: "black", backgroundColor: "green" }
          : params.value > 2 && params.value < 5
          ? { color: "black", backgroundColor: "orange" }
          : { color: "black", backgroundColor: "red" },
      valueFormatter: (params) => `${params.value}`,
    },
  ]);
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));
  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  // Example using Grid's API
  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);
  var ragCellClassRules = {
    "rag-green-outer": function (params) {
      return params.value === 2008;
    },
    "rag-amber-outer": function (params) {
      return params.value === 2004;
    },
    "rag-red-outer": function (params) {
      return params.value === 2000;
    },
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    // console.log(rowData);
    setGridApi(params.api);
  };

  const getRowHeight = (params) => (params.node.group ? 40 : 40);

  const gridOptions = {
    getRowStyle: (params) => {
      if (params.node.rowPinned === "bottom") {
        return { backgroundColor: "#f9f8f9" };
      }
    },
    rowClass: (params) => {
      return params.node.rowIndex === SendDataToCampOptimization.length
        ? "bottom-row"
        : "";
    },
    // other grid options...
  };

  //   const columnDefs = [
  //     { headerName: "Name", field: "name" },
  //     { headerName: "Value", field: "value" },
  //   ];
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  useEffect(() => {
    // This code will run only when myVariable changes
    console.log(`startDate changed to ${startDate} ---- ${endDate}`);
  }, [startDate, endDate]);
  return (
    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <span style={{ color: "#1a1523", fontSize: 18 }}>
            Campaign Optimization Overview
            {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
            <span style={{ color: "#6f6e77" }}>{startDate}</span> */}
          </span>
          <AgGridReact
            cellClassRules={ragCellClassRules}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={SendDataToCampOptimization} // Row Data for Rows //{[...SendDataToFirstTable, sumRow]} add another column had a total values
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            onCellClicked={cellClickedListener} // Optional - registering for Grid Event
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            suppressScrollOnNewData={true}
            getRowHeight={getRowHeight}
            domLayout="autoHeight"
            pagination={true}
            paginationPageSize={10}
          />
        </div>{" "}
      </div>{" "}
      <style>
        {`
                              .ag-theme-alpine .ag-root-wrapper {
                                border: none;
                              }
                            `}
        {`
                              .my-header-class {
                                background-color: white;
                              }
                            `}
      </style>
    </div>
  );
}
