import React, { useState, useEffect } from "react";
import axios from "axios";
import { MyUrl } from "../../../App";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Lottie from "lottie-react";
import reloadpage from "../../../reloadpage.json";

import { Divider } from "antd";
import Calendar from "../../../Images/Calendar.png";
import GlobalFirstGraph from "./GlobalFirstGraph";
import GlobalDonuts from "./GlobalDonuts";
import GlobalFirstTable from "./GlobalFirstTable";
import GlobalSecondTable from "./GlobalSecondTable";
import GlobalThirdTable from "./GlobalThirdTable";
import GlobalFourthTable from "./GlobalFourthTable";
import jwt_decode from "jwt-decode";
var jwtToken = "";

var SendDataToFirstGraph = [];
var ObjSendDataToFirstGraph = {};
var SendDataToFirstGraph1 = [];

var SendDataToGlobalDonuts = [];
var ObjSendDataToGlobalDonuts = {};

var SendDataToGlobalDonuts1 = [];
var ObjSendDataToGlobalDonuts1 = {};

var SendDataToGlobalDonuts2 = [];
var ObjSendDataToGlobalDonuts2 = {};

var SendDataToFirstTable = [];
var ObjSendDataToFirstTable = {};
var SendDataToFirstTable1 = [];

var SendDataToSecondTable = [];
var ObjSendDataToSecondTable = {};
var SendDataToSecondTableLast = [];
var SendDataToSecondTableLast1 = [];

var ObjSendDataToSecondTableLast = {};
var SendDataToSecondTable1 = [];
var SendDataToThirdTable = [];
var ObjSendDataToThirdTable = {};
var SendDataToThirdTable1 = [];

var SendDataToFourthTable = [];
var ObjSendDataToFourthTable = {};

var compName = "Demand";
var PickedDate = 0;
const styles = {
  root: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {},
  circle: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    border: "2px solid #fff", // we can add color after solid
  },
  checkedIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "white",
  },
};

const CircleCheckbox = withStyles(styles)(({ classes, ...props }) => (
  <Checkbox
    {...props}
    classes={{
      root: classes.root,
      checked: classes.checked,
    }}
    icon={<span className={classes.circle} />}
    checkedIcon={
      <span className={classes.checkedIcon}>
        <CheckIcon style={{ fontSize: 16, color: "black" }} />
      </span>
    }
  />
));

export default function GlobalGetDataAndFilter() {
  const [isClicked, setIsClicked] = useState(false);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const items1 = localStorage.getItem("Token");
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  //const MyToken = JSON.parse(localStorage.getItem("Token"));
  const MyToken = JSON.parse(sessionStorage.getItem("Token"));

  const base64Url = MyToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(atob(base64));
  const expirationDate = new Date(payload.exp * 1000);
  const currentTime = new Date().getTime(); // Get the current time in milliseconds

  const RefreshFunc1 = async () => {
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);

    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token); //parsed;
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            GetMonthlyIncomeFunc1(startDate, endDate);
            GetRevenueDataFunc(startDate, endDate);
            GetTop10AppsFunc(startDate, endDate);
            GetTopDemanDataFunc(startDate, endDate);
            GetMonthlyIncomeFunc(startDate, endDate);
            //RefreshFunc(Today, Today);
            GetDailyIncomeFunc1(startDate, endDate);
            GetDailyIncomeFunc();

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed;
      GetMonthlyIncomeFunc1(startDate, endDate);
      GetRevenueDataFunc(startDate, endDate);
      GetTop10AppsFunc(startDate, endDate);
      GetTopDemanDataFunc(startDate, endDate);
      GetMonthlyIncomeFunc(startDate, endDate);
      //RefreshFunc(Today, Today);
      GetDailyIncomeFunc1(startDate, endDate);
      GetDailyIncomeFunc();

      return () => {
        source.cancel("Component unmounted");
      };
    }
  };
  const RefreshFunc = async (startDate, endDate) => {
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);

    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token); // parsed;
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            // GetMonthlyIncomeFunc(startDate, endDate);
            GetMonthlyIncomeFunc1(startDate, endDate);
            GetTop10AppsFunc(startDate, endDate);
            GetTopDemanDataFunc(startDate, endDate);
            GetRevenueDataFunc(startDate, endDate);
            GetMonthlyIncomeFunc(startDate, endDate);
            GetDailyIncomeFunc1(startDate, endDate);

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed;
      GetMonthlyIncomeFunc1(startDate, endDate);
      GetTop10AppsFunc(startDate, endDate);
      GetTopDemanDataFunc(startDate, endDate);
      GetRevenueDataFunc(startDate, endDate);
      GetMonthlyIncomeFunc(startDate, endDate);
      GetDailyIncomeFunc1(startDate, endDate);

      return () => {
        source.cancel("Component unmounted");
      };
    }
  };

  useEffect(() => {
    setChooceCompanies([]);
    RefreshFunc1();
    //RefreshFunc(Today, Today);
  }, []);

  // useEffect(() => {
  //   RefreshFunc();
  //   RefreshFunc1();
  // }, []);
  // const items1 = localStorage.getItem("User");
  // const MyToken = JSON.parse(localStorage.getItem("Token"));
  // useEffect(() => {
  //   if (items1 === null) {
  //     const items = sessionStorage.getItem("User");
  //     const pass = sessionStorage.getItem("Password");
  //     var parsed = JSON.parse(items);
  //     var myPassword = JSON.parse(pass);
  //     setCheckTypeOfUser(parsed.role);
  //     console.log(parsed.role);
  //   } else {
  //     const items = sessionStorage.setItem("User", JSON.stringify(items1));
  //     const pass = sessionStorage.getItem("Password");
  //     var myPassword = JSON.parse(pass);
  //     var parsed = JSON.parse(items1);
  //     setCheckTypeOfUser(parsed.role);
  //     console.log(parsed.role);
  //   }
  // }, []);

  const [chooceCompanies, setChooceCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  const [isLoading1, setIsLoading1] = useState(true);
  const [data1, setData1] = useState("");
  const [isLoading2, setIsLoading2] = useState(true);
  const [data2, setData2] = useState("");
  const [isLoading3, setIsLoading3] = useState(true);
  const [data3, setData3] = useState("");
  const [isLoading4, setIsLoading4] = useState(true);
  const [data4, setData4] = useState("");
  const [isLoading5, setIsLoading5] = useState(true);
  const [data5, setData5] = useState("");
  const [isLoading6, setIsLoading6] = useState(true);
  const [data6, setData6] = useState("");
  const [isLoading7, setIsLoading7] = useState(true);

  //when pick today
  const Today = moment().add("days").format("YYYY-MM-DD");
  //when pick yesterday
  const Yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  //when pick 7 days
  const SevenDays = moment().subtract(7, "day").format("YYYY-MM-DD");

  var LastDayInMonth = moment(LastDayInMonth)
    .endOf("month")
    .format("YYYY-MM-DD");

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");

  const currentDate = moment();
  const daysInCurrentMonth = currentDate.daysInMonth();

  const handleCheckboxChange = (event, productName) => {
    if (event.target.checked) {
      // setIsLoading2(true);
      // setTimeout(() => {
      //   // setCount(count + 1);
      //   setIsLoading2(false);
      // }, 0);
    }
    if (event.target.checked) {
      setChooceCompanies([...chooceCompanies, productName]); // add to array
      // for (let i = 0; i < SendDataToFirstGraph1.length; i++) {
      //   if (SendDataToFirstGraph1[i].id === productName) {
      //     //console.log(SendDataToFirstGraph1[i]);
      //     SendDataToFirstGraph.push(SendDataToFirstGraph1[i]);
      //   }
      // }
      // for (let i = 0; i < SendDataToFirstTable1.length; i++) {
      //   if (SendDataToFirstTable1[i].id === productName) {
      //     console.log(SendDataToFirstTable1[i]);
      //     SendDataToFirstTable.push(SendDataToFirstTable1[i]);
      //     //console.table(SendDataToFirstTable); // see table in console
      //   }
      // }
    } else {
      setChooceCompanies(
        chooceCompanies.filter((name) => name !== productName)
      ); // remove from array
      // SendDataToFirstGraph = SendDataToFirstGraph.filter(
      //   (item) => item.id !== productName
      // );
      // SendDataToFirstTable = SendDataToFirstTable.filter(
      //   (item) => item.id !== productName
      // );
      // SendDataToThirdTable = SendDataToThirdTable.filter(
      //   (item) => item.id !== productName
      // );
    }
  };

  const products = ["Product A", "Product B", "Product C"];

  const idsArray = [1, 2, 3, 4];

  const IdsToGetData = chooceCompanies
    .map((id) => "&ids=" + String(id))
    .join("");

  const ChooceTable = ["Demand", "10 Apps"];
  const [CompColor, setCompColor] = useState(0);

  // const GetDailyIncomeFunc = () => {
  //   const items = sessionStorage.getItem("Token");
  //   var parsed = JSON.parse(items);

  //   setIsLoading(true);
  //   try {
  //     let base64 = require("base-64"); // install it before use from npm i base-64
  //     axios
  //       .get(
  //         //    https://localhost:7184/api/Dashboard/getMonthlyIncome?startDate=2023-04-07&endDate=2023-04-07
  //         `${MyUrl}/api/Dashboard/getMonthlyIncome?&startDate=${startDate}&endDate=${endDate}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
  //           },
  //           cancelToken: source.token,
  //         }
  //       )
  //       .then((res) => {
  //         const data = res.data;
  //         setData(data);
  //         for (let i = 0; i < data.responseData.length; i++) {
  //           setChooceCompanies((prevCompanies) => [
  //             ...prevCompanies,
  //             data.responseData[i].id,
  //           ]);
  //         }
  //       })
  //       .catch((error) => {})
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const GetDailyIncomeFunc = () => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(`${MyUrl}/api/Account/userSeats`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(items).replace("Bearer ", "")}`,
          },
          cancelToken: source.token,
        })
        .then((res) => {
          const data = res.data;
          setData(data);
          //console.log(data);
          for (let i = 0; i < data.length; i++) {
            setChooceCompanies((prevCompanies) => [
              ...prevCompanies,
              data[i].id,
            ]);
          }
          setIsLoading(false);
        })
        .catch((error) => {})
        .finally(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  const GetDailyIncomeFunc1 = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading2(true);
    console.log(startDate);
    console.log(endDate);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`, //getDailyIncome
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data2 = res.data;
          console.log(data2);
          setData2(data2);
          SendDataToFirstGraph = [];
          SendDataToFirstGraph1 = [];
          console.log(SendDataToFirstGraph1);
          for (let i = 0; i < data2.responseData.length; i++) {
            for (
              let j = 0;
              j < data2.responseData[i].dataNetto.data.length;
              j++
            ) {
              ObjSendDataToFirstGraph = {
                id: data2.responseData[i].id,
                name: data2.responseData[i].name,
                hour: data2.responseData[i].dataNetto.data[j].hour.name,
                impressionsGood:
                  data2.responseData[i].dataNetto.data[j].impressionsGood,
              };
              SendDataToFirstGraph.push(ObjSendDataToFirstGraph);
              SendDataToFirstGraph1.push(ObjSendDataToFirstGraph);
            }
          }
          setIsLoading2(false);
        })
        .catch((error) => {})
        .finally(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  const GetMonthlyIncomeFunc1 = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading6(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`, //getDailyIncome
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          SendDataToFirstTable = [];
          SendDataToFirstTable1 = [];
          //  ObjSendDataToFirstTable = {};
          for (let i = 0; i < data2.responseData.length; i++) {
            ObjSendDataToFirstTable = {
              id: data2.responseData[i].id,
              seat: data2.responseData[i].name,
              impressionsGood:
                data2.responseData[i].dataNetto.sumResp.impressionsGood,
              revenueTotal:
                data2.responseData[i].dataNetto.sumResp.revenueTotal,
              mediaCost: data2.responseData[i].dataNetto.sumResp.mediaCost,
              operationCost:
                data2.responseData[i].dataNetto.sumResp.operationCost,
              grossProfit: data2.responseData[i].dataNetto.sumResp.grossProfit,
              partnerFee: data2.responseData[i].dataNetto.sumResp.partnerFee,
              netProfit: data2.responseData[i].dataNetto.sumResp.netProfit,
              grossProfitMargin:
                data2.responseData[i].dataNetto.sumResp.grossProfitMargin,
            };
            SendDataToFirstTable.push(ObjSendDataToFirstTable);
            SendDataToFirstTable1.push(ObjSendDataToFirstTable);
          }
          setIsLoading6(false);
        })
        .catch((error) => {})
        .finally(() => {});
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // setChooceCompanies([]);
    // PickDatesToGetData(0);
    // GetTop10AppsFunc();
    // GetTopDemanDataFunc();
    // GetRevenueDataFunc();
    // GetDailyIncomeFunc();
    // GetMonthlyIncomeFunc();
    // return () => {
    //   source.cancel("Component unmounted");
    // };
  }, []);

  const GetMonthlyIncomeFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading1(true);
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data1 = res.data;
          setData1(data1);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  const GetRevenueDataFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading3(true);
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getRevenueData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data3 = res.data;
          setData3(data3);
          console.log(data3);
          //data For Revenue by Seat Donut
          SendDataToGlobalDonuts = [];
          for (let i = 0; i < data3.responseData.length; i++) {
            ObjSendDataToGlobalDonuts = {
              name: data3.responseData[i].name,
              value: Number(
                data3.responseData[
                  i
                ].dataNetto.data[0].envResponse.globalRevenue.toFixed(2)
              ),
            };
            SendDataToGlobalDonuts.push(ObjSendDataToGlobalDonuts);
          }
          //End data For Revenue by Seat Donut
          console.log(data3.responseData);

          //data For Revenue by Env
          for (let i = 0; i < 1; i++) {
            let sumOfMoblie = 0;
            let sumOfCTV = 0;

            SendDataToGlobalDonuts1 = [];
            for (let j = 0; j < data3.responseData.length; j++) {
              // console.log(data3.responseData[j].dataNetto.data[0].envResponse);
              // console.log(
              //   data3.responseData[j].dataNetto.data[0].envResponse.data.length
              // );
              for (
                let q = 0;
                q <
                data3.responseData[j].dataNetto.data[0].envResponse.data.length;
                q++
              ) {
                // console.log(
                //   data3.responseData[j].dataNetto.data[0].envResponse.data[q]
                //     .environment.id
                // );

                if (
                  data3.responseData[j].dataNetto.data[0].envResponse.data[q]
                    .environment.id === "ctv"
                ) {
                  sumOfCTV += Number(
                    data3.responseData[j].dataNetto.data[0].envResponse.data[
                      q
                    ].revenue // only change last data from 0 to 1
                      .toFixed(2)
                  );
                }
                if (
                  data3.responseData[j].dataNetto.data[0].envResponse.data[q]
                    .environment.id === "mobile_app"
                ) {
                  sumOfMoblie += Number(
                    data3.responseData[j].dataNetto.data[0].envResponse.data[
                      q
                    ].revenue // only change last data from 0 to 1
                      .toFixed(2)
                  );
                }
              }
            }
            SendDataToGlobalDonuts1 = [
              {
                name: "Mobile",
                value: Number(sumOfMoblie.toFixed(2)),
              },
              {
                name: "CTV", // only change last data from 0 to 1
                value: Number(sumOfCTV.toFixed(2)),
              },
            ];
            //SendDataToGlobalDonuts1.push(ObjSendDataToGlobalDonuts1);
          }
          // End data For Revenue by Env
          // For Revenue by Integration

          SendDataToGlobalDonuts2 = [];
          ObjSendDataToGlobalDonuts2 = {};

          // for (let i = 0; i < 1; i++) {
          //   let sumOfTagBased = 0;
          //   let sumOfOpenRTB = 0;
          //   let sumOfDirectDemand = 0;
          //   console.log(
          //     data3.responseData[0].dataNetto.data[0].integrationResponse
          //   );
          //   for (let j = 0; j < data3.responseData.length; j++) {
          //     for (
          //       let s = 0;
          //       s <
          //       data3.responseData[j].dataNetto.data[0].integrationResponse.data
          //         .length;
          //       s++
          //     ) {
          //       // console.log(s);

          //       if (
          //         data3.responseData[j].dataNetto.data[0].integrationResponse
          //           .data[s].integrationType.code === "tag_based"
          //       ) {
          //         //console.log("tag_based");
          //         sumOfTagBased +=
          //           data3.responseData[j].dataNetto.data[0].integrationResponse
          //             .data[s].revenue;

          //         //console.log(sumOfTagBased);
          //       }
          //       if (
          //         data3.responseData[j].dataNetto.data[0].integrationResponse
          //           .data[s].integrationType.code === "rtb"
          //       ) {
          //         sumOfOpenRTB +=
          //           data3.responseData[j].dataNetto.data[0].integrationResponse
          //             .data[s].revenue;
          //       }
          //       if (
          //         data3.responseData[j].dataNetto.data[0].integrationResponse
          //           .data[s].integrationType.code === "direct_demand"
          //       ) {
          //         sumOfDirectDemand +=
          //           data3.responseData[j].dataNetto.data[0].integrationResponse
          //             .data[s].revenue;
          //       }
          //     }
          //   }

          //   SendDataToGlobalDonuts2 = [
          //     {
          //       name: "Tag Based",
          //       value: Number(sumOfTagBased.toFixed(2)),
          //     },
          //     {
          //       name: "Open RTB", // only change last data from 0 to 1
          //       value: Number(sumOfOpenRTB.toFixed(2)),
          //     },
          //     {
          //       name: "Direct demand", // only change last data from 0 to 1
          //       value: Number(sumOfDirectDemand.toFixed(2)),
          //     },
          //   ];
          //   //console.log(SendDataToGlobalDonuts2);
          // }

          for (
            let i = 0;
            i < data3.responseData[0].dataNetto.revSumOfIntegrationTypes.length;
            i++
          ) {
            console.log(
              data3.responseData[0].dataNetto.revSumOfIntegrationTypes[i]
                .integrationType
            );

            console.log(
              data3.responseData[0].dataNetto.revSumOfIntegrationTypes[i]
                .revenue
            );

            ObjSendDataToGlobalDonuts2 = {
              name: data3.responseData[0].dataNetto.revSumOfIntegrationTypes[i]
                .integrationType,
              value:
                data3.responseData[0].dataNetto.revSumOfIntegrationTypes[i]
                  .revenue,
            };
            SendDataToGlobalDonuts2.push(ObjSendDataToGlobalDonuts2);

            // SendDataToGlobalDonuts2 = [
            //   {
            //     name: data3.responseData[0].dataNetto.revSumOfIntegrationTypes[
            //       i
            //     ].integrationType,
            //     value:
            //       data3.responseData[0].dataNetto.revSumOfIntegrationTypes[i]
            //         .revenue,
            //   },
            // ];
          }
          //End For Revenue by Integration

          // For Table Media Type
          SendDataToSecondTable = [];
          ObjSendDataToSecondTable = {};
          SendDataToSecondTable1 = [];

          let MyCtv = 0;
          let MyMobile = 0;
          let sumOfDirectDemand = 0;

          for (let i = 0; i < 1; i++) {
            SendDataToSecondTable = [];
            SendDataToSecondTable1 = [];

            for (let j = 0; j < data3.responseData.length; j++) {
              //console.log(data3.responseData[j].dataNetto.data[0]);
              //console.log(data3.responseData[j].name);

              for (
                let s = 0;
                s <
                data3.responseData[j].dataNetto.data[0].envResponse.data.length;
                s++
              ) {
                if (
                  data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                    .environment.id === "ctv"
                ) {
                  MyCtv =
                    data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                      .revenue;
                  // console.log(
                  //   data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                  //     .environment.id
                  // );
                  // console.log(
                  //   data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                  //     .revenue
                  // );
                }
                if (
                  data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                    .environment.id === "mobile_app"
                ) {
                  MyMobile =
                    data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                      .revenue;
                  // console.log(
                  //   data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                  //     .environment.id
                  // );
                  // console.log(
                  //   data3.responseData[j].dataNetto.data[0].envResponse.data[s]
                  //     .revenue
                  // );
                }
              }

              ObjSendDataToSecondTable = {
                name: data3.responseData[j].name,
                InApp: MyMobile,
                CTV: MyCtv,
                total: MyMobile + MyCtv,
              };
              SendDataToSecondTable.push(ObjSendDataToSecondTable);
              // console.log(MyCtv);
              // console.log(MyMobile);
              MyCtv = 0;
              MyMobile = 0;
              //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
              // console.log(
              //   data3.responseData[j].dataNetto.data[0].envResponse.data[q]
              //     .environment.id
              // );
              // console.log(
              //   data3.responseData[j].dataNetto.data[0].envResponse.data
              //     .length
              // );
              // console.log(
              //   data3.responseData[j].dataNetto.data[0].envResponse.data[0]
              // );
              // console.log(data3.responseData.length);
              // ObjSendDataToSecondTable = {
              //   name: data3.responseData[j].name,
              //   InApp: Number(
              //     data3.responseData[
              //       j
              //     ].dataNetto.data[0].envResponse.data[0].revenue.toFixed(2)
              //   ),
              //   CTV: Number(
              //     data3.responseData[
              //       j
              //     ].dataNetto.data[0].envResponse.data[1].revenue // only change last data from 0 to 1
              //       .toFixed(2)
              //   ),
              //   total:
              //     Number(
              //       data3.responseData[
              //         j
              //       ].dataNetto.data[0].envResponse.data[0].revenue.toFixed(2)
              //     ) +
              //     Number(
              //       data3.responseData[
              //         j
              //       ].dataNetto.data[0].envResponse.data[1].revenue // only change last data from 0 to 1
              //         .toFixed(2)
              //     ),
              // };
              // SendDataToSecondTable.push(ObjSendDataToSecondTable);
            }
          }
          SendDataToSecondTableLast = [];
          ObjSendDataToSecondTableLast = {};
          SendDataToSecondTableLast1 = [];

          for (let i = 0; i < SendDataToSecondTable.length; i++) {
            ObjSendDataToSecondTableLast = {
              name: SendDataToSecondTable[i].name,
              CTV: SendDataToSecondTable[i].CTV,
              InApp: SendDataToSecondTable[i].InApp,
              CTVPrecent:
                (SendDataToSecondTable[i].CTV /
                  SendDataToSecondTable[i].total) *
                100,
              InAppPrecent:
                (SendDataToSecondTable[i].InApp /
                  SendDataToSecondTable[i].total) *
                100,
            };
            SendDataToSecondTableLast.push(ObjSendDataToSecondTableLast);
            SendDataToSecondTableLast1.push(ObjSendDataToSecondTableLast);
          }

          // console.log(SendDataToSecondTableLast);
          // console.log(SendDataToSecondTableLast1);

          //End  For Table Media Type
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading3(false);
        });
    } catch (error) {}
  };

  const GetTopDemanDataFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading4(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getDemandData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}&daily=false
          `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data4 = res.data;
          setData4(data4);
          setIsLoading4(data4);
          SendDataToThirdTable = [];
          SendDataToThirdTable1 = [];
          ObjSendDataToThirdTable = {};

          for (let i = 0; i < data4.responseData.data.length; i++) {
            ObjSendDataToThirdTable = {
              id: i,
              BillingName: data4.responseData.data[i].advertiser.billingName,
              Impressions: data4.responseData.data[i].impressions_good,
              Revenue: data4.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data4.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            SendDataToThirdTable.push(ObjSendDataToThirdTable);
            SendDataToThirdTable1.push(ObjSendDataToThirdTable);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading4(false);
        });
    } catch (error) {}
  };

  const GetTop10AppsFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading5(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getAppsData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}&daily=false
          `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data5 = res.data;
          setData5(data5);
          SendDataToFourthTable = [];
          ObjSendDataToFourthTable = {};

          for (let i = 0; i < data5.responseData.data.length; i++) {
            ObjSendDataToFourthTable = {
              id: i,
              AppBundle: data5.responseData.data[i].app_bundle.name,
              AppName: data5.responseData.data[i].app_name.name,
              OS: data5.responseData.data[i].os.name,
              Impressions: data5.responseData.data[i].impressions_good,
              Revenue: data5.responseData.data[i].revenue_total,
              Fill: data5.responseData.data[i].fill,
              QS: Number.parseFloat(
                data5.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
              // Impressions: data55.responseData.data[i].impressions_good,
              // Revenue: data55.responseData.data[i].revenue_total,
              // QS: Number.parseFloat(
              //   data55.responseData.data[i].qs.toString().replace("e-", "")
              // ).toFixed(2),
            };
            SendDataToFourthTable.push(ObjSendDataToFourthTable);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading5(false);
        });
    } catch (error) {}
  };
  //For CheckBox
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  //End for CheckBox

  //Select Days
  const [days, setDays] = React.useState(0);
  const handleChangeDays = (event) => {
    setDays(event.target.value);
    // PickDatesToGetData(event.target.value);
  };
  //End select Days

  const [OpenRange, SetOpenRange] = useState(false);
  var startDate = Today;
  var endDate = Today;
  async function PickDatesToGetData(event) {
    setDays(event);
    if (event === 0) {
      SetOpenRange(false);
      startDate = Today;
      endDate = Today;
      await RefreshFunc(startDate, endDate);
      // GetMonthlyIncomeFunc(Today, Today);
    } else if (event === 1) {
      SetOpenRange(false);
      startDate = Yesterday;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 2) {
      SetOpenRange(false);
      startDate = SevenDays;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 3) {
      SetOpenRange(false);
      startDate = FirstDayInMonth;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 4) {
      if (value.$D === undefined) {
        startDate = Today;
      } else {
        startDate = `${value.$y}-${value.$M + 1}-${value.$D}`;
      }
      if (value1.$D === undefined) {
        endDate = Today;
      } else {
        endDate = `${value1.$y}-${value1.$M + 1}-${value1.$D}`;
      }
      SetOpenRange(true);
      await RefreshFunc(startDate, endDate);
    }
  }

  useEffect(() => {
    // This code will run only when myVariable changes
  }, [OpenRange]);

  //For Date Pick

  var [value, setValue] = React.useState(startDate);
  var [value1, setValue1] = React.useState(endDate);
  const handleChange3 = (newValue) => {
    setValue(newValue);
    startDate = `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`;
    //console.log(`startDate = ${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
  };

  const handleChange4 = (newValue1) => {
    setValue1(newValue1);
    endDate = `${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`;
    // console.log(
    //   `endDate = ${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`
    // );
  };

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  return (
    <div style={{ paddingLeft: "2.5%", paddingRight: "1%", paddingTop: 30 }}>
      {/* <h2>Select Seat:</h2> */}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            //overflowX:''
            overflowX: width > 766 ? "auto" : "scroll",
          }}
        >
          {" "}
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            width={750}
            height={80}
            borderRadius={100}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              //justifyContent: "space-between",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                //overflowX:''
                overflowX: width > 766 ? "auto" : "scroll",
              }}
            >
              {Array.isArray(data) ? (
                data &&
                data.map((responseData) => (
                  <div>
                    <div
                      key={responseData.id}
                      style={{
                        display: "flex",
                        background: responseData.color,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 3,
                        paddingRight: 5,
                        height: 40,
                      }}
                    >
                      <CircleCheckbox
                        checked={chooceCompanies.includes(responseData.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, responseData.id)
                        }
                      />
                      <label>{responseData.name}</label>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <h5>There is no companies!</h5>
                </>
              )}
              <button
                style={{
                  border: "none",
                  background: "white",
                  cursor: "pointer",
                  minWidth: 60,
                }}
                onClick={() => {
                  setIsLoading1(true);
                  setIsLoading2(true);
                  setIsLoading3(true);
                  setIsLoading4(true);
                  setIsLoading5(true);
                  setIsLoading6(true);
                  PickDatesToGetData(PickedDate);
                  setIsClicked(true);
                  setTimeout(() => setIsClicked(false), 1000);
                }}
              >
                <Lottie
                  loop={isClicked ? true : false}
                  autoplay={isClicked ? true : false}
                  animationData={reloadpage}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",

                    display: "flex",
                    background: "white",
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 3,
                    paddingRight: 5,
                    height: 40,
                    width: 60,
                    minWidth: 60,
                  }}
                  isPaused={isClicked}
                />
              </button>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "1%",
              }}
            >
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">days</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={days}
                    label="days"
                    onChange={handleChangeDays}
                  >
                    <MenuItem
                      sx={{ width: 200 }}
                      value={0}
                      onClick={() => {
                        PickedDate = 0;
                        PickDatesToGetData(0);
                      }}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      value={1}
                      onClick={() => {
                        PickedDate = 1;

                        PickDatesToGetData(1);
                      }}
                    >
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        PickedDate = 2;

                        PickDatesToGetData(2);
                      }}
                    >
                      Last 7 Days
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={() => {
                        PickedDate = 3;

                        PickDatesToGetData(3);
                      }}
                    >
                      This Months
                    </MenuItem>
                    <div style={{ height: 1 }}>
                      <Divider />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={value}
                          onChange={handleChange3}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        &nbsp;&nbsp;
                        <DesktopDatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={value1}
                          onChange={handleChange4}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>

                    <br></br>

                    <MenuItem
                      value={4}
                      onClick={() => {
                        PickedDate = 4;

                        SetOpenRange(true);
                        // PickDatesToGetData(4);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "black",
                            textTransform: "none",
                            fontSize: "100%",
                            borderRadius: "12px",
                          }}
                          onClick={() => {
                            if (value === "" || value1 === "") {
                            } else {
                              SetOpenRange(false);
                              PickDatesToGetData(4);
                            }
                          }}
                        >
                          {/* Filter */}
                          Custom Range
                        </Button>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      ></div>
      {/* {OpenRange ? (
        <div>
          <div className="centerIt">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="DD-MM-YYYY"
                value={value}
                onChange={handleChange3}
                renderInput={(params) => <TextField {...params} />}
              />
              &nbsp;&nbsp;
              <h6>-</h6>
              &nbsp;&nbsp;
              <DesktopDatePicker
                label="To Date"
                inputFormat="DD-MM-YYYY"
                value={value1}
                onChange={handleChange4}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>{" "}
            <h6></h6>
          </div>
          <div className="centerIt">
            <br></br>
            <br></br>
            <Button
              variant="contained"
              style={{
                backgroundColor: "black",
                textTransform: "none",
                fontSize: "100%",
                borderRadius: "12px",
              }}
              onClick={() => {
                if (value === "" || value1 === "") {
                  console.log("Wrong");
                } else {
                  console.log("Call Data");

                  PickDatesToGetData(4);
                }
              }}
            >
              Filter
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )} */}
      {/* <h3>Selected Products:</h3>
        <ul>
          {chooceCompanies.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </ul>
        <h3>{days}</h3> */}
      {isLoading2 ? (
        <>
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={210}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <>
          <GlobalFirstGraph SendDataToFirstGraph={SendDataToFirstGraph} />
        </>
      )}
      {isLoading1 ? (
        <>
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={200}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <></>
      )}
      {isLoading3 ? (
        <>
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={210}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <>
          {" "}
          <GlobalDonuts
            SendDataToGlobalDonuts={SendDataToGlobalDonuts}
            SendDataToGlobalDonuts1={SendDataToGlobalDonuts1}
            SendDataToGlobalDonuts2={SendDataToGlobalDonuts2}
          />
        </>
      )}
      {isLoading6 ? (
        <>
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={210}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <>
          {CheckTypeOfUser !== "Partner" ? (
            <>
              {" "}
              <GlobalFirstTable
                SendDataToFirstTable={SendDataToFirstTable}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {isLoading6 ? (
        <>
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={210}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <>
          {CheckTypeOfUser !== "Partner" ? (
            <>
              <GlobalSecondTable
                SendDataToSecondTableLast={SendDataToSecondTableLast}
                SendDataToFirstTable={SendDataToFirstTable}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <span style={{ color: "#1a1523", fontSize: 18 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Top &nbsp;&nbsp;
          {ChooceTable.map((responseData, i) => (
            <div
              style={{
                background: "#f4f2f4",
                borderTopLeftRadius: i === 0 ? 50 : 0,
                borderBottomLeftRadius: i === 0 ? 50 : 0,
                borderTopRightRadius: i === ChooceTable.length - 1 ? 50 : 0,
                borderBottomRightRadius: i === ChooceTable.length - 1 ? 50 : 0,
                height: 40,
                alignItems: "center",
                paddingLeft: i === 0 ? 3 : 0,
                paddingRight: i === ChooceTable.length - 1 ? 3 : 0,
                //justifyContent: "center",
                display: "flex",
              }}
            >
              <button
                value={responseData}
                style={{
                  borderWidth: 0,
                  borderRadius: 50,
                  background: CompColor === i ? "white" : "#f4f2f4",
                  height: 35,
                  fontWeight: CompColor === i ? "bold" : "",
                  fontSize: 14,
                  minWidth: 80,
                }}
                onClick={() => {
                  setCompColor(i);
                  compName = responseData;
                  // setComponentName(responseData.component.name);
                  //console.log(responseData);
                }}
              >
                {responseData}
              </button>
              {i !== ChooceTable.length - 1 ? (
                <div
                  style={{
                    width: 1,
                    height: 16,
                    flexGrow: 0,
                    background: "#c8c7cb",
                  }}
                ></div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </span>

      <div style={{ paddingBottom: 30 }}>
        {compName === "Demand" ? (
          <>
            {isLoading4 ? (
              <>
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  //width={210}
                  height={500}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <GlobalThirdTable
                  SendDataToThirdTable={SendDataToThirdTable}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            )}
          </>
        ) : (
          <>
            {isLoading5 ? (
              <>
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  //width={210}
                  height={500}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <GlobalFourthTable
                  SendDataToFourthTable={SendDataToFourthTable}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
