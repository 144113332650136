import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import moment from "moment";
import { uniq, findIndex } from "@antv/util";
import { debounce } from "lodash";

export default function GlobalFirstGraph({ SendDataToFirstGraph, MyChooce2 }) {
  const currentDate = moment().format("MMMM DD, YYYY");
  const customTooltipFormatter = (datum) => {
    return {
      name: datum.name,
      value: `${datum.impressionsGood
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    };
  };

  // console.log(currentDate);
  //console.log(MyChooce2);
  console.log("SendDataToFirstGraph");

  //  console.log(SendDataToFirstGraph);

  // console.log(aa);
  // console.log(aa.length);

  const COLOR_PLATE_10 = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
  ];
  const container = document.getElementById("container");
  const config = {
    data: SendDataToFirstGraph,
    legend: false, // This will hide the legend

    xField: "hour",
    yField: "impressionsGood",
    seriesField: "name",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ name }) => {
        return "circle";
      },
      style: ({ hour }) => {
        return {
          r: hour, // 4 个数据示一个点标记
        };
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ width: "100%" }), []);
  return (
    <div
      style={{
        paddingRight: "1%",
        paddingLeft: "2.5%",
      }}
    >
      <>
        <div style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}>
          <span style={{ color: "#1a1523", fontSize: 18 }}>
            Impressions by Hours
            {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "} */}
            {/* <span style={{ color: "#6f6e77" }}>{currentDate}</span> */}
          </span>
        </div>
        <div style={containerStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            <div>
              <Line {...config} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
