import React from "react";
import { Switch, Route, withRouter, Link, Navigate } from "react-router-dom";
import ForCheck from "../Pages/ForCheck";
import Dashboard from "../Pages/Dashboard";
import Income from "../Pages/Income";
import Global from "../Pages/Global";
import CustomReporting from "../Pages/CustomReporting";
import Demand from "../Pages/Demand";
import SupplyTeamReport from "../Pages/SupplyTeamReport";
import NewHeader from "../newComponents/NewHeader";
import MainPage from "../newComponents/MainPage";
import MainPageLeftDrawer from "../newComponents/MainPageLeftDrawer";
import ChangePassword from "../newComponents/Pages/ChangePassword";
import { Helmet } from "react-helmet";

function Router() {
  return (
    <div>
      <Helmet>
        <title>Monetize BI</title>
        {/* <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="./Images/MMLogoForTab.png"
      
    /> */}
        <link rel="icon" type="image/png" href="/MMLogoForTab.png" />
      </Helmet>
      ;
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route exact path="/Dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/Income">
          <Income />
        </Route>
        <Route exact path="/Global">
          <Global />
        </Route>
        <Route exact path="/ForCheck">
          <ForCheck />
        </Route>
        <Route exact path="/CustomReporting">
          <CustomReporting />
        </Route>
        <Route exact path="/Demand">
          <Demand />
        </Route>
        <Route exact path="/SupplyTeamReport">
          <SupplyTeamReport />
        </Route>

        {/*New Pages */}
        <Route exact path="/NewHeader">
          <NewHeader />
        </Route>
        <Route exact path="/MainPage">
          <MainPage />
        </Route>
        <Route exact path="/MainPageLeftDrawer">
          <MainPageLeftDrawer />
        </Route>
        <Route exact path="/ChangePassword">
          <ChangePassword />
        </Route>
      </Switch>
    </div>
  );
}
//MainPage
export default withRouter(Router);
