import DailyNet from "../../../Images/DailyNet.png";
import MonthlyNet from "../../../Images/MonthlyNet.png";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";

export default function DashboardTopOfPage({
  isLoading,
  isLoading1,
  SumMonthlyForTopOfPageToSend,
  SumDailyForTopOfPageToSend,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        //justifyContent: "center",
        // alignItems: "center",
        paddingLeft: "2.5%",
        paddingTop: "1%",
        // flexWrap: "wrap",
        paddingRight: "1%",
        flexDirection: "row",
        overflowX: width > 766 ? "auto" : "scroll",
      }}
    >
      <div
        style={{
          height: 150,
          //width: "48%",
          backgroundImage: "linear-gradient(283deg, #e1fbe8 100%, #ecffd4 0%)",
          borderRadius: 12,
          display: "flex",
          justifyContent: "space-between",
          flex: width < 770 ? "0 1 100%" : "0 1 48%",
        }}
      >
        <div
          style={{
            width: "50%",
            alignItems: "center",
            display: "flex",
            paddingLeft: "5%",
            justifyContent: "flex-end",
          }}
        >
          <span
            style={{
              fontSize: width > 770 ? 16 : 12,
            }}
          >
            <a style={{ color: "black" }}>Daily Net Income Projection</a>
            <br></br>
            {isLoading ? (
              <>
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  width={width > 770 ? 210 : 100}
                  height={50}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <span style={{ fontSize: width > 770 ? 32 : 20 }}>
                  {SumDailyForTopOfPageToSend.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  $
                </span>
              </>
            )}
          </span>
        </div>
        <div
          style={{
            paddingRight: "5%",
          }}
        >
          <img
            src={DailyNet}
            style={{ width: width / 3, maxWidth: width > 770 ? 150 : 100 }}
          ></img>
        </div>
      </div>
      {width < 770 ? <div style={{ marginRight: 10 }}>{"   "}</div> : ""}
      <div style={{ width: "4%" }}>{/* {width} */}</div>
      <div
        style={{
          height: 150,
          // width: "48%",
          backgroundImage: "linear-gradient(283deg, #fecdd3 100%, #ebedfc 0%)",
          borderRadius: 12,
          display: "flex",
          justifyContent: "space-between",
          flex: width < 770 ? "0 1 100%" : "0 1 48%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            paddingLeft: "5%",
            width: 300,
            justifyContent: "flex-end",
            width: "50%",
          }}
        >
          <span
            style={{
              fontSize: width > 770 ? 16 : 12,
              justifyContent: "center",
            }}
          >
            Monthly Net Income Projection
            <br></br>
            {isLoading1 ? (
              <>
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  width={width > 770 ? 210 : 100}
                  height={50}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <span style={{ fontSize: width > 770 ? 32 : 20 }}>
                  {SumMonthlyForTopOfPageToSend.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  $
                </span>
              </>
            )}
          </span>
        </div>
        <div
          style={{
            paddingRight: "5%",
          }}
        >
          <img
            src={MonthlyNet}
            style={{ width: width / 3, maxWidth: width > 770 ? 150 : 100 }}
          ></img>
        </div>
      </div>
    </div>
  );
}
