import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MyUrl } from "../../../App";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { Divider } from "antd";
import IncomeFirstTable from "./IncomeFirstTable";
import IncomeGraph from "./IncomeGraph";
import IncomeSecondTable from "./IncomeSecondTable";
import jwt_decode from "jwt-decode";
import Lottie from "lottie-react";
import UnboxtheBlackbox from "../../../UnboxtheBlackbox.json";
import loading33refresh from "../../../loading33refresh.json";
import loadingrefresh from "../../../loadingrefresh.json";
import refreshicon from "../../../refreshicon.json";
import reloadpage from "../../../reloadpage.json";

var jwtToken = "";

var SendDataToFirstTable = [];
var ObjSendDataToFirstTable = {};

var SendDataToFirstTable1 = [];
var ObjSendDataToFirstTable1 = {};

var SendDataToIncomeGraph = [];
var ObjSendDataToIncomeGraph = {};
var SendDataToIncomeGraph1 = [];
var SendDataToSecondTable = [];
var SendDataToSecondTable1 = [];

var TotalOfDailyChange = 0;
var currentValue1 = 0;
var prevValue1 = 0;
var AfterCalc1 = currentValue1 - prevValue1;
var PickedDate = 0;
const styles = {
  root: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {},
  circle: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    border: "2px solid #fff", // we can add color after solid
  },
  checkedIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "white",
  },
};

const CircleCheckbox = withStyles(styles)(({ classes, ...props }) => (
  <Checkbox
    {...props}
    classes={{
      root: classes.root,
      checked: classes.checked,
    }}
    icon={<span className={classes.circle} />}
    checkedIcon={
      <span className={classes.checkedIcon}>
        <CheckIcon style={{ fontSize: 16, color: "black" }} />
      </span>
    }
  />
));

export default function IncomeGetDataAndFilter() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  const source = axios.CancelToken.source();
  const items1 = sessionStorage.getItem("Token");
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  const MyToken = JSON.parse(sessionStorage.getItem("Token"));
  const base64Url = MyToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(atob(base64));
  const expirationDate = new Date(payload.exp * 1000);
  const currentTime = new Date().getTime(); // Get the current time in milliseconds

  const RefreshFunc1 = async () => {
    // const items = sessionStorage.getItem("Token");
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token);
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            GetDailyIncomeFunc();
            GetMonthlyIncomeFunc(startDate, endDate);
            GetDailyIncomeFunc1(startDate, endDate);
            //RefreshFunc(Today, Today);

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const token = sessionStorage.getItem("Token");
      //var parsed = JSON.parse(items);
      jwtToken = JSON.parse(token);
      GetDailyIncomeFunc();
      GetMonthlyIncomeFunc(startDate, endDate);
      GetDailyIncomeFunc1(startDate, endDate);
      //RefreshFunc(Today, Today);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };
  const RefreshFunc = async (startDate, endDate) => {
    const token = sessionStorage.getItem("Token");
    //var parsed = JSON.parse(token);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token);
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            GetMonthlyIncomeFunc(startDate, endDate);
            GetDailyIncomeFunc1(startDate, endDate);
            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed
      GetMonthlyIncomeFunc(startDate, endDate);
      GetDailyIncomeFunc1(startDate, endDate);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };

  const [chooceCompanies, setChooceCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  const [isLoading1, setIsLoading1] = useState(true);
  const [data1, setData1] = useState("");
  const [isLoading2, setIsLoading2] = useState(true);
  const [data2, setData2] = useState("");

  //when pick today
  const Today = moment().add("days").format("YYYY-MM-DD");
  //when pick yesterday
  const Yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  //when pick 7 days
  const SevenDays = moment().subtract(7, "day").format("YYYY-MM-DD");

  var LastDayInMonth = moment(LastDayInMonth)
    .endOf("month")
    .format("YYYY-MM-DD");

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");

  const currentDate = moment();
  const daysInCurrentMonth = currentDate.daysInMonth();
  const [selectedObject, setSelectedObject] = useState(true);
  const [count, setCount] = useState(0);

  const handleCheckboxChange = (event, productName) => {
    if (event.target.checked) {
      // setIsLoading2(true);
      // setTimeout(() => {
      //   // setCount(count + 1);
      //   setIsLoading2(false);
      // }, 0);

      setChooceCompanies([...chooceCompanies, productName]); // add to array
      // for (let i = 0; i < SendDataToFirstTable1.length; i++) {
      //   if (SendDataToFirstTable1[i].id === productName) {
      //     console.log(SendDataToFirstTable1[i]);
      //     SendDataToFirstTable.push(SendDataToFirstTable1[i]);
      //     //console.table(SendDataToFirstTable); // see table in console
      //   }
      // }
      // for (let i = 0; i < SendDataToIncomeGraph1.length; i++) {
      //   if (SendDataToIncomeGraph1[i].id === productName) {
      //     console.log(SendDataToIncomeGraph1[i]);
      //     SendDataToIncomeGraph.push(SendDataToIncomeGraph1[i]);
      //   }
      // }
      //setIsLoading2(false);
    } else {
      setChooceCompanies(
        chooceCompanies.filter((name) => name !== productName)
      ); // remove from array

      // SendDataToFirstTable = SendDataToFirstTable.filter(
      //   (item) => item.id !== productName
      // );
      // SendDataToIncomeGraph = SendDataToIncomeGraph.filter(
      //   (item) => item.id !== productName
      // );
      // SendDataToSecondTable = SendDataToSecondTable.filter(
      //   (item) => item.id !== productName
      // );
      console.log(SendDataToSecondTable);
      console.log("SendDataToIncomeGraph1");
      console.log(SendDataToSecondTable1);
    }
  };

  const products = ["Product A", "Product B", "Product C"];

  const idsArray = [1, 2, 3, 4];

  const IdsToGetData = chooceCompanies
    .map((id) => "&ids=" + String(id))
    .join("");
  const GetDailyIncomeFunc = () => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(`${MyUrl}/api/Account/userSeats`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(items).replace("Bearer ", "")}`,
          },
          cancelToken: source.token,
        })
        .then((res) => {
          const data = res.data;
          setData(data);
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            setChooceCompanies((prevCompanies) => [
              ...prevCompanies,
              data[i].id,
            ]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetDailyIncomeFunc1 = (startDate, endDate) => {
    SendDataToFirstTable.length = 0;
    ObjSendDataToFirstTable = {};
    SendDataToFirstTable1 = 0;
    ObjSendDataToFirstTable1 = {};
    SendDataToIncomeGraph.length = 0;
    SendDataToIncomeGraph1.length = 0;
    ObjSendDataToIncomeGraph = {};
    const items = sessionStorage.getItem("Token");
    //  console.log(items);
    var parsed = JSON.parse(items);
    //  console.log(parsed);

    setIsLoading2(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          //getMonthlyIncome
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          console.log(data2);
          //this data for IncomeFirstTable
          SendDataToFirstTable = [];
          SendDataToFirstTable1 = [];
          for (let i = 0; i < data2.responseData.length; i++) {
            ObjSendDataToFirstTable = {
              id: data2.responseData[i].id,
              name: data2.responseData[i].name,
              revenueTotal:
                data2.responseData[i].dataNetto.sumResp.revenueTotal,
              mediaCost: data2.responseData[i].dataNetto.sumResp.mediaCost,
              servingFee: data2.responseData[i].dataNetto.sumResp.servingFee,
              netProfit: data2.responseData[i].dataNetto.sumResp.netProfit,
              partnerFee: data2.responseData[i].dataNetto.sumResp.partnerFee,
              pixalateFee: data2.responseData[i].dataNetto.sumResp.pixalateFee,

              grossProfit: data2.responseData[i].dataNetto.sumResp.grossProfit,
              grossProfitMargin:
                data2.responseData[i].dataNetto.sumResp.grossProfitMargin,
              netIncome: data2.responseData[i].dataNetto.sumResp.netIncome,
              procentNetIncome:
                data2.responseData[i].dataNetto.sumResp.procentNetIncome,
            };
            SendDataToFirstTable.push(ObjSendDataToFirstTable);
            SendDataToFirstTable1.push(ObjSendDataToFirstTable);
          }
          //End this data for IncomeFirstTable
          SendDataToIncomeGraph = [];
          SendDataToIncomeGraph1 = [];
          for (let i = 0; i < data2.responseData.length; i++) {
            ObjSendDataToIncomeGraph = {
              id: data2.responseData[i].id,
              name: "Revenue",
              company: data2.responseData[i].name,
              value: Number(
                data2.responseData[i].dataNetto.sumResp.revenueTotal.toFixed(2)
              ),
            };
            SendDataToIncomeGraph.push(ObjSendDataToIncomeGraph);
            SendDataToIncomeGraph1.push(ObjSendDataToIncomeGraph);
          }
          for (let i = 0; i < data2.responseData.length; i++) {
            ObjSendDataToIncomeGraph = {
              id: data2.responseData[i].id,
              name: "Net Income",
              company: data2.responseData[i].name,
              value: Number(
                data2.responseData[i].dataNetto.sumResp.netIncome.toFixed(2)
              ),
            };
            SendDataToIncomeGraph.push(ObjSendDataToIncomeGraph);
            SendDataToIncomeGraph1.push(ObjSendDataToIncomeGraph);
          }
          //End this data for IncomeGraph
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading2(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setChooceCompanies([]);
    RefreshFunc1();
    //RefreshFunc(Today, Today);
  }, []);

  const GetMonthlyIncomeFunc = (startDate, endDate) => {
    setIsLoading1(true);
    const items = sessionStorage.getItem("Token");
    //console.log(items);
    var parsed = JSON.parse(items);
    //console.log(parsed);
    const token = sessionStorage.getItem("Token");
    const decodedToken = jwt_decode(token);
    //console.log(decodedToken);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    try {
      setIsLoading1(true);

      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data1 = res.data;
          setData1(data1);
          console.log(data1);
          SendDataToSecondTable = [];
          SendDataToSecondTable1 = [];

          for (
            let i = 0;
            i < data1.responseData[0].dataNetto.data.length;
            i++
          ) {
            let revenueTotal = 0;
            let impressions = 0;
            let scannedImpressions = 0;
            let addRequests = 0;
            let grossProfit = 0;
            let mediaCost = 0;
            let servingFee = 0;
            let netIncome = 0;
            let scanningfee = 0;
            let pixalateFee = 0;
            for (let j = 0; j < data1.responseData.length; j++) {
              pixalateFee +=
                data1.responseData[j].dataNetto.data[i].pixalateFee;
              revenueTotal +=
                data1.responseData[j].dataNetto.data[i].revenueTotal;
              impressions +=
                data1.responseData[j].dataNetto.data[i].impressionsGood;
              scannedImpressions +=
                data1.responseData[j].dataNetto.data[i].scannedImpressions;
              addRequests +=
                data1.responseData[j].dataNetto.data[i].addRequests;
              grossProfit +=
                data1.responseData[j].dataNetto.data[i].grossProfit;
              mediaCost += data1.responseData[j].dataNetto.data[i].mediaCost;
              servingFee += data1.responseData[j].dataNetto.data[i].servingFee;
              netIncome +=
                role === "Partner"
                  ? data1.responseData[j].dataNetto.data[i].netProfit
                  : data1.responseData[j].dataNetto.data[i].netIncome;
              scanningfee +=
                data1.responseData[j].dataNetto.data[i].pixalateFee;
            }
            SendDataToSecondTable.push({
              id: data1.responseData[0].id,
              date: data1.responseData[0].dataNetto.data[i].date.id,
              revenueTotal: revenueTotal,
              impressions: impressions,
              scannedImpressions: scannedImpressions,
              addRequests: addRequests,
              grossProfit: grossProfit,
              mediaCost: mediaCost,
              servingFee: servingFee,
              netIncome: netIncome,
              netIncome1: netIncome,
              marketplacefee: 0,
              scanningfee: scanningfee,
              pixalateFee: pixalateFee,
            });
            SendDataToSecondTable1.push({
              id: data1.responseData[0].id,
              date: data1.responseData[0].dataNetto.data[i].date.id,
              revenueTotal: revenueTotal,
              impressions: impressions,
              scannedImpressions: scannedImpressions,
              addRequests: addRequests,
              grossProfit: grossProfit,
              mediaCost: mediaCost,
              servingFee: servingFee,
              netIncome: netIncome,
              netIncome1: netIncome,
              marketplacefee: 0,
              scanningfee: scanningfee,
              pixalateFee: pixalateFee,
            });
          }
          TotalOfDailyChange = 0;
          for (let i = 1; i < SendDataToSecondTable.length; i++) {
            currentValue1 = SendDataToSecondTable[i].netIncome;
            if (i != 0) {
              prevValue1 = SendDataToSecondTable[i - 1].netIncome;
              AfterCalc1 = currentValue1 - prevValue1;
              TotalOfDailyChange += AfterCalc1;
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  //For CheckBox
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  //End for CheckBox

  //Select Days
  const [days, setDays] = React.useState(0);
  const handleChangeDays = (event) => {
    setDays(event.target.value);
    // PickDatesToGetData(event.target.value);
  };
  //End select Days

  const [OpenRange, SetOpenRange] = useState(false);
  var startDate = Today;
  var endDate = Today;

  async function PickDatesToGetData(event) {
    setDays(event);
    if (event === 0) {
      SetOpenRange(false);
      startDate = Today;
      endDate = Today;
      await RefreshFunc(startDate, endDate);
      // GetDailyIncomeFunc1(Today, Today);
      // GetMonthlyIncomeFunc(Today, Today);
    } else if (event === 1) {
      SetOpenRange(false);
      startDate = Yesterday;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 2) {
      SetOpenRange(false);
      startDate = SevenDays;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 3) {
      SetOpenRange(false);
      startDate = FirstDayInMonth;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 4) {
      if (value.$D === undefined) {
        startDate = Today;
      } else {
        startDate = `${value.$y}-${value.$M + 1}-${value.$D}`;
      }
      if (value1.$D === undefined) {
        endDate = Today;
      } else {
        endDate = `${value1.$y}-${value1.$M + 1}-${value1.$D}`;
      }
      SetOpenRange(true);
      await RefreshFunc(startDate, endDate);
    }
  }

  // useEffect(() => {
  //   // This code will run only when myVariable changes
  // }, [OpenRange]);
  //For Date Pick
  var [value, setValue] = React.useState(startDate);
  var [value1, setValue1] = React.useState(endDate);
  const handleChange3 = (newValue) => {
    setValue(newValue);
    startDate = `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`;
  };

  const handleChange4 = (newValue1) => {
    setValue1(newValue1);
    endDate = `${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`;
  };
  const [isClicked, setIsClicked] = useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: UnboxtheBlackbox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottieRef = useRef(null);

  return (
    <div style={{ paddingLeft: "2.5%", paddingRight: "1%", paddingTop: 30 }}>
      {/* <h2>Select Products:</h2> */}

      <div></div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: width > 766 ? "auto" : "scroll",
          }}
        >
          {" "}
          <Skeleton
            animation="wave"
            width={750}
            height={80}
            borderRadius={100}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                overflowX: width > 766 ? "" : "scroll",
              }}
            >
              {Array.isArray(data) ? (
                data &&
                data.map((responseData) => (
                  <div>
                    <div
                      key={responseData.id}
                      style={{
                        display: "flex",
                        background: responseData.color,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 3,
                        paddingRight: 5,
                        height: 40,
                      }}
                    >
                      <CircleCheckbox
                        //key={responseData.id}
                        //onClick={() => setSelectedObject(responseData)}
                        checked={chooceCompanies.includes(responseData.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, responseData.id)
                        }
                      />
                      <label>{responseData.name}</label>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <h5>There is no companies!</h5>
                </>
              )}
              <button
                style={{
                  border: "none",
                  background: "white",
                  cursor: "pointer",
                  minWidth: 60,

                  // // alignItems: "center",
                  // // justifyContent: "center",
                  // background: "white",
                  // color: "white",
                  // border: "none",
                  // borderRadius: 50,
                  // cursor: "pointer",
                  // //scale: 1.5,
                  // //margin: 20,
                }}
                onClick={() => {
                  setIsLoading2(true);
                  setIsLoading1(true);
                  PickDatesToGetData(PickedDate);
                  setIsClicked(true);
                  setTimeout(() => setIsClicked(false), 1000);
                }}
              >
                <Lottie
                  loop={isClicked ? true : false}
                  autoplay={isClicked ? true : false}
                  animationData={reloadpage}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                    // scale: 1.5,
                    // width: 60,
                    // height: 60,
                    // // margin: 20,
                    display: "flex",
                    background: "white",
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 3,
                    paddingRight: 5,
                    height: 40,
                    width: 60,
                    minWidth: 60,
                  }}
                  isPaused={isClicked}
                />
              </button>
            </div> */}
            {/* <button
              style={{
                cursor: "pointer",
                background: "#818cf8",
                borderRadius: 10,
                borderWidth: 0,
                color: "white",
              }}
              onClick={() => {
                PickDatesToGetData(PickedDate);
              }}
            >
              Call data
            </button> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "1%",
              }}
            >
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">days</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={days}
                    label="days"
                    onChange={handleChangeDays}
                  >
                    <MenuItem
                      sx={{ width: 200 }}
                      value={0}
                      onClick={() => {
                        PickedDate = 0;
                        PickDatesToGetData(0);
                      }}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      value={1}
                      onClick={() => {
                        PickedDate = 1;
                        PickDatesToGetData(1);
                      }}
                    >
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        PickedDate = 2;
                        PickDatesToGetData(2);
                      }}
                    >
                      Last 7 Days
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={() => {
                        PickedDate = 3;
                        PickDatesToGetData(3);
                      }}
                    >
                      This Months
                    </MenuItem>
                    <div style={{ height: 1 }}>
                      <Divider />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={value}
                          onChange={handleChange3}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        &nbsp;&nbsp;
                        <DesktopDatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={value1}
                          onChange={handleChange4}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>

                    <br></br>

                    <MenuItem
                      value={4}
                      onClick={() => {
                        PickedDate = 4;
                        SetOpenRange(true);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "black",
                            textTransform: "none",
                            fontSize: "100%",
                            borderRadius: "12px",
                          }}
                          onClick={() => {
                            if (value === "" || value1 === "") {
                            } else {
                              SetOpenRange(false);
                              PickDatesToGetData(4);
                            }
                          }}
                        >
                          Custom Range
                        </Button>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </>
      )}

      {isLoading2 ? (
        <>
          <Skeleton animation="wave" height={500} borderRadius={100} />
        </>
      ) : (
        <>
          <IncomeFirstTable
            SendDataToFirstTable={SendDataToFirstTable}
            startDate={startDate}
            endDate={endDate}
            CheckTypeOfUser={CheckTypeOfUser}
          />
          <IncomeGraph
            SendDataToIncomeGraph={SendDataToIncomeGraph}
            startDate={startDate}
          />
        </>
      )}

      {isLoading1 ? (
        <>
          <Skeleton animation="wave" height={500} />
        </>
      ) : (
        <>
          {" "}
          <IncomeSecondTable
            TotalOfDailyChange={TotalOfDailyChange}
            SendDataToSecondTable={SendDataToSecondTable}
            startDate={startDate}
            endDate={endDate}
            CheckTypeOfUser={CheckTypeOfUser}
          />
        </>
      )}
    </div>
  );
}
